import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../../../config';
import apiService from '../../../utlis/apiService';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import {
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@mui/material';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateUserForm = () => {
  let query = useQuery();
  let id = query.get('id');
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState({
    "Approval Management": [
      "readApproval",
      "approveApproval",
    ],
    "Registration Management": [
      "readRegistration",
      "approveRegistration",
    ],
    "Faculty Management": [
      "createFaculty",
      "readFaculty",
      "updateFaculty",
      "deleteFaculty"
    ],
    "Student Management":[
      "createStudent",
      "readStudent",
      "updateStudent",
      "deleteStudent",
      "manageOperations",
      "manageAccounts",
    ],
    "Mentor Management":[
      "createMentor",
      "readMentor",
      "updateMentor",
      "deleteMentor"
    ],
    "Coordinator Management":[
      "createCoordinator",
      "readCoordinator",
      "updateCoordinator",
      "deleteCoordinator"
    ],
    "Branch Management":[
      "createBranch",
      "readBranch",
      "updateBranch",
      "deleteBranch"
    ],
    "Classroom Management":[
      "createClassroom",
      "readClassroom",
      "updateClassroom",
      "deleteClassroom"
    ],
    "Batch Management":[
      "createBatch",
      "readBatch",
      "updateBatch",
      "deleteBatch",
      "readExamMark",
      "enterExamMark",
      "updateNameTail",
    ],
    "Academic Holiday":[
      "createAcademicHoliday",
      "readAcademicHoliday",
      "updateAcademicHoliday",
      "deleteAcademicHoliday",
    ],
    "Subject Management":[
      "createSubject",
      "readSubject",
      "updateSubject",
      "deleteSubject"
    ],
    "Course Management":[
      "createCourse",
      "readCourse",
      "updateCourse",
      "deleteCourse"
    ],
    "Topic Management":[
      "createTopic",
      "readTopic",
      "updateTopic",
      "deleteTopic"
    ],
    "Category Management":[
      "createCategory",
      "readCategory",
      "updateCategory",
      "deleteCategoryl"
    ],
    "Schedule Management":[
      "createSchedule",
      "readSchedule",
      "updateSchedule",
      "deleteSchedule",
      "updateDate",
      "updateAttendance"
    ],
    "Exam Management":[
      "createExam",
      "readExam",
      "updateExam",
      "deleteExam"
    ],
    "Role Management":[
      "createRole",
      "readRole",
      "updateRole",
      "deleteRole"
    ],
    "User Management":[
      "createUser",
      "readUser",
      "updateUser",
      "deleteUser"
    ],
    
  });

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
  });


  useEffect(() => {
    const fetchRoleData = async () => {
      try {
        if (id) {
          const response = await apiService.get('/admin/getRole', {
            params: {
              id: id,
            },
          });
          console.log(response.data);
          const { name, permissions } = response.data;
          setFormData({ name });
          setSelectedPermissions(permissions);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchRoleData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePermissionChange = (permission) => {
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(selectedPermissions.filter(p => p !== permission));
    } else {
      setSelectedPermissions([...selectedPermissions, permission]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const apiUrl = id
        ? `${config.apiUrl}/admin/updateRole?id=${id}`
        : `${config.apiUrl}/admin/createRole`;
      try {
        const response = await apiService.post(apiUrl, {
          name: formData.name,
          permissions: selectedPermissions,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };

  const validateForm = () => {
    // Implement validation logic here
    return true;
  };

  return (
    <div>
      <h1 style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
        <BackButton />
        {`Roles`}
      </h1>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit' : 'Create'} Role
        </Typography>

        <TextField
          label="Role Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
        />

        <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
          Permissions:
        </Typography>

        {Object.entries(permissions).map(([module, modulePermissions]) => (
          <div key={module} style={{ marginBottom: '20px' }}>
            <Typography variant="h6" gutterBottom style={{ marginBottom: '10px' }}>
              {module}
            </Typography>
            <FormGroup>
              {modulePermissions.map((permission) => (
                <FormControlLabel
                  key={permission}
                  control={
                    <Checkbox
                      checked={selectedPermissions.includes(permission)}
                      onChange={() => handlePermissionChange(permission)}
                      name={permission}
                    />
                  }
                  label={permission}
                  style={{ marginLeft: '10px' }}
                />
              ))}
            </FormGroup>
          </div>
        ))}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: '20px' }}
        >
          {id ? 'Update Role' : 'Create Role'}
        </Button>
      </form>
    </div>
  );
};

export default CreateUserForm;
