import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  CssBaseline,
  IconButton,
  Container,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  BarChart as BarChartIcon,
  ExitToApp as ExitToAppIcon,
  Business as BranchsIcon,
  AccountBalance as AccountBalanceIcon,
  Accessibility as AccessibilityIcon,
  Person4 as Person4Icon,
  Diversity3 as ClassRoomIcon,
  MenuBook as CourseIcon,
  LibraryBooks as PaperIcon,
  DynamicFeed as BatchIcon,
  Class as TopicIcon,
  AccessTimeFilled as SheduleIcon,
  Layers as LevelIcon,
  Group as RoleIcon,
  Hail as UserIcon,
  EditNote as EditNoteIcon,
  PendingActions as PendingActionsIcon,
  AppRegistration as AppRegistrationIcon,
  HolidayVillage as HolidayVillageIcon
} from '@mui/icons-material';
import { BrowserRouter as Router, Route, Routes, Link,useNavigate } from 'react-router-dom';
import Dashboard from './pages/dashboard/index'
import StudentsPage from './pages/students/list';
import StudentCreate from './pages/students/create';
import StudentView from './pages/students/view';
import UserPage from './pages/users/list';
import UserCreate from './pages/users/create';
import FacultyPage from './pages/faculties/list'
import FacultyCreate from './pages/faculties/create'
import BranchPage from './pages/branches/list'
import BranchCreate from './pages/branches/create'
import MentorPage from './pages/mentors/list'
import MentorCreate from './pages/mentors/create'
import CoordinatorPage from './pages/coordinators/list'
import CoordinatorCreate from './pages/coordinators/create'
import ClassPage from './pages/classrooms/list'
import ClassCreate from './pages/classrooms/create'
import CoursPage from './pages/courses/list'
import CourseCreate from './pages/courses/create'
import PaperPage from './pages/papers/list'
import PaperCreate from './pages/papers/create'
import TopicPage from './pages/topics/list'
import TopicCreate from './pages/topics/create'
import BatchPage from './pages/batchs/list'
import BatchCreate from './pages/batchs/create'
import BatchView from './pages/batchs/view'
import LevelPage from './pages/levels/list'
import LevelCreate from './pages/levels/create'
import LevelView from './pages/levels/view'
import SchedulePage from './pages/schedule/list'
// import ScheduleGenerator from './pages/scheduleGenerator/create';
import ScheduleCreate from './pages/schedule/create'
import ScheduleView from './pages/schedule/view'
import ScheduleCreateMultiple from './pages/schedule/createMultiple';
import ExamPage from './pages/exams/list';
import ExamCreate from './pages/exams/create';
import ExamView from './pages/exams/view';
import RolesPage from './pages/roles/list'
import RolesCreate from './pages/roles/create'
import StudentReport from './pages/reports/students'
import BatchReport from './pages/reports/batchs'
import AcademicReport from './pages/reports/acadamic'
import AttendanceRreport from './pages/reports/attendance'
import TransferReport from './pages/reports/transfers'
import { useUser } from '../context/userContext';

import { LicenseInfo } from '@mui/x-license-pro';
import MarkAttendance from './pages/batchs/attendance';
import ScheduleReport from './pages/reports/schedule';
import FeeReceivableReport from './pages/reports/feeReceivable';
import FeePaymentReport from './pages/reports/feePayment';
import ActivityLogs from './pages/reports/activityLog';
import Approvals from './pages/approvals/list';
import Registrations from './pages/registrations/list';
import AcademicHolidayList from './pages/academicHoliday/list';
import CreateAcademicHoliday from './pages/academicHoliday/create';

LicenseInfo.setLicenseKey("63cdcff003c86a961f1b47b5703dd5e0Tz0wLEU9MjUzNDA0ODY0MDAwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sS1Y9Mg==")

const AdminPanel = () => {
  const { permissions,logout } = useUser();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState('/');
  const [userPermissions,setUserpermissions]=useState([])
  

  useEffect(() => {
    const pathname = window.location.pathname;
    setSelectedItem(pathname);

    // console.log(permissions)
  }, []);

  useEffect(()=>{
    setUserpermissions(permissions)
  },[permissions])


  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleItemClick = (path) => {
    setSelectedItem(path);
  };


  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/admin');
    logout()
    window.location.reload();
  };


  return (
    // <Router>
      <div style={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" style={{ zIndex: 1201 }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              style={{ marginRight: '36px' }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Elance - ERP
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: open ? 240 : 64,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: open ? 240 : 64,
              boxSizing: 'border-box',
            },
          }}
          open={open}
        >
          <Toolbar />
          <List>
            <Typography style={{ ...{ marginLeft: 16, marginTop: 8, opacity: 0.5 }, ...(open ? { fontSize: 13 } : { fontSize: 8 }) }}>
              Main
            </Typography>
            {[
              { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
            ].map((item, index) => (
              <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }} key={index}>
                <ListItem
                  button
                  onClick={() => {
                    handleItemClick(item.path);
                  }}
                  selected={selectedItem === item.path}
                  className={selectedItem === item.path ? 'selected-item' : ''}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))}

          {permissions && permissions.some(permission => ['readStudent', 'readFaculty','readMentor','readCoordinator','readBranch','readClassroom','readBatch'].includes(permission)) &&
            <>
            {/* Management Section */}
            
            <Typography style={{ ...{ marginLeft: 16, marginTop: 8, opacity: 0.5 }, ...(open ? { fontSize: 13 } : { fontSize: 8 }) }}>
              Manage
            </Typography>
            {[
              { text: 'Approvals', icon: <PendingActionsIcon />, path: '/dashboard/approvals/list' ,permission: 'readApproval'},
              { text: 'Registrations', icon: <AppRegistrationIcon />, path: '/dashboard/registrations/list', permission: 'readRegistration'},
              { text: 'Students', icon: <PeopleIcon />, path: '/dashboard/students/list' ,permission: 'readStudent'},
              { text: 'Faculties', icon: <AccessibilityIcon />, path: '/dashboard/faculty/list',permission: 'readFaculty' },
              { text: 'Mentors', icon: <Person4Icon />, path: '/dashboard/mentor/list',permission: 'readMentor' },
              // { text: 'Coordinators', icon: <Person4Icon />, path: '/dashboard/coordinators/list',permission: 'readCoordinator' },
              { text: 'Branches', icon: <AccountBalanceIcon />, path: '/dashboard/branch/list',permission: 'readBranch' },
              { text: 'Class Rooms', icon: <ClassRoomIcon />, path: '/dashboard/class/list',permission: 'readClassroom' },
              { text: 'Batch', icon: <BatchIcon />, path: '/dashboard/batch/list' ,permission: 'readBatch'},
              { text: 'Academic Holiday', icon: <HolidayVillageIcon />, path: '/dashboard/academic-holiday/list', permission: 'readAcademicHoliday'},
            ].map((item, index) => (
              permissions && permissions.includes(item.permission) &&
              <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }} key={index}>
                <ListItem
                  button
                  onClick={() => {
                    handleItemClick(item.path);
                  }}
                  selected={selectedItem === item.path}
                  className={selectedItem === item.path ? 'selected-item' : ''}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))}
            </>
          }
      
      {permissions && permissions.some(permission => ['readCourse', 'readCategory','readSubject','readSchedule', 'readExam'].includes(permission)) &&

      <>
        {/* Course  */}
        <Typography style={{ ...{ marginLeft: 16, marginTop: 8, opacity: 0.5 }, ...(open ? { fontSize: 13 } : { fontSize: 8 }) }}>
          Acadamic
        </Typography>
        {[
          { text: 'Courses', icon: <CourseIcon />, path: '/dashboard/course/list',permission: 'readCourse' },
          { text: 'Categories', icon: <LevelIcon />, path: '/dashboard/category/list',permission: 'readCategory' },
          { text: 'Subjects', icon: <PaperIcon />, path: '/dashboard/subject/list',permission: 'readSubject' },
          { text: 'Topics', icon: <TopicIcon />, path: '/dashboard/topic/list',permission: 'readTopic' },
          { text: 'Schedules', icon: <SheduleIcon />, path: '/dashboard/schedule/list',permission: 'readSchedule'},
          { text: 'Exams', icon: <EditNoteIcon />, path: '/dashboard/exam/list',permission: 'readExam'},
          // { text: 'Schedule Generator', icon: <SheduleIcon />, path: '/dashboard/generate/schedule', permission: 'readExam'},
          
        ].map((item, index) => (
          permissions && permissions.includes(item.permission) &&
          <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }} key={index}>
            <ListItem
              button
              onClick={() => {
                handleItemClick(item.path);
              }}
              selected={selectedItem === item.path}
              className={selectedItem === item.path ? 'selected-item' : ''}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          </Link>
        ))}
      </>
    }

    {permissions && permissions.some(permission => ['readRole', 'readUser'].includes(permission)) &&
    <>
       {/* Managment  */}
           <Typography style={{ ...{ marginLeft: 16, marginTop: 8, opacity: 0.5 }, ...(open ? { fontSize: 13 } : { fontSize: 8 }) }}>
              Admin
            </Typography>
            {[
              { text: 'Role', icon: <RoleIcon />, path: '/dashboard/roles/list',permission: 'readRole' },
              { text: 'Users', icon: <UserIcon />, path: '/dashboard/users/list',permission: 'readUser'},
              
            ].map((item, index) => (
              permissions &&  permissions.includes(item.permission) &&
              <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }} key={index}>
                <ListItem
                  button
                  onClick={() => {
                    handleItemClick(item.path);
                  }}
                  selected={selectedItem === item.path}
                  className={selectedItem === item.path ? 'selected-item' : ''}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))}
      </>
      }
                  {/* Reports Section */}
            <Typography style={{ ...{ marginLeft: 16, marginTop: 8, opacity: 0.5 }, ...(open ? { fontSize: 13 } : { fontSize: 8 }) }}>
              Reports
            </Typography>
            {[
              // { text: 'Analytics', icon: <BarChartIcon />, path: '/analytics' },
              { text: 'Schedule', icon: <BarChartIcon />, path: '/dashboard/schedule-report' },
              { text: 'Fee Receivable', icon: <BarChartIcon />, path: '/dashboard/fee-receivable-report' },
              { text: 'Fee Payment', icon: <BarChartIcon />, path: '/dashboard/fee-payment-report' },
              { text: 'Students', icon: <BarChartIcon />, path: '/dashboard/student-report' },
              { text: 'Batches', icon: <BarChartIcon />, path: '/dashboard/batch-report' },
              { text: 'Academics', icon: <BarChartIcon />, path: '/dashboard/academic-report' },
              { text: 'Attendance', icon: <BarChartIcon />, path: '/dashboard/attendance-report' },
              { text: 'Transfer', icon: <BarChartIcon />, path: '/dashboard/transfer-report' },
              { text: 'Activity Logs', icon: <BarChartIcon />, path: '/dashboard/activity-logs' },
            ].map((item, index) => (
              // permissions && permissions.includes(item.permission) &&
              <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }} key={index}>
                <ListItem
                  button
                  onClick={() => {
                    handleItemClick(item.path);
                  }}
                  selected={selectedItem === item.path}
                  className={selectedItem === item.path ? 'selected-item' : ''}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))}
          </List>
          <List style={{ marginTop: 'auto', position: 'sticky', bottom: 0 }}>
          <ListItem  
            onClick={handleLogout} 
            style={{ backgroundColor: 'white' }} // Set background color to white
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
        </Drawer>
        <Container component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }} maxWidth={false}>
          <Toolbar />
          <Routes>
            <Route path="/*" element={<Dashboard />} />
            <Route path="/approvals/list" element={<Approvals />}  />
            <Route path="/registrations/list" element={<Registrations />}  />
            <Route path="/students/list" element={<StudentsPage />}  />
            <Route path="/students/add" element={<StudentCreate />} />
            <Route path="/students/view" element={<StudentView />} />
            <Route path="/users/list" element={<UserPage />} />
            <Route path="/users/add" element={<UserCreate />} />
            <Route path="/roles/list" element={<RolesPage />} />
            <Route path="/roles/add" element={<RolesCreate />} />
            <Route path="/faculty/list" element={<FacultyPage />} />
            <Route path="/faculty/add" element={<FacultyCreate />} />
            <Route path="/branch/list" element={<BranchPage />} />
            <Route path="/branch/add" element={<BranchCreate />} />
            <Route path="/coordinators/list" element={<CoordinatorPage />} />
            <Route path="/coordinator/add" element={<CoordinatorCreate />} />
            <Route path="/mentor/list" element={<MentorPage />} />
            <Route path="/mentor/add" element={<MentorCreate />} />
            <Route path="/class/list" element={<ClassPage />} />
            <Route path="/class/add" element={<ClassCreate />} />
            <Route path="/course/list" element={<CoursPage />} />
            <Route path="/course/add" element={<CourseCreate />} />
            <Route path="/subject/list" element={<PaperPage />} />
            <Route path="/paper/add" element={<PaperCreate />} />
            <Route path="/topic/list" element={<TopicPage />} />
            <Route path="/topic/add" element={<TopicCreate />} />
            <Route path="/batch/list" element={<BatchPage />} />
            <Route path="/batch/add" element={<BatchCreate />} />
            <Route path="/batch/view" element={<BatchView />} />
            <Route path="/batch/schedule/attendance" element={<MarkAttendance />} />
            <Route path="/category/list" element={<LevelPage />} />
            <Route path="/category/add" element={<LevelCreate />} />
            <Route path="/category/view" element={<LevelView />} />
            <Route path="/schedule/list" element={<SchedulePage />} />
            <Route path="/schedule/add" element={<ScheduleCreate />} />
            <Route path="/schedule/view" element={<ScheduleView />} />
            <Route path="/schedule/add/multiple" element={<ScheduleCreateMultiple />} />
            {/* <Route path="/generate/schedule" element={<ScheduleGenerator />} /> */}
            <Route path="/exam/list" element={<ExamPage />} />
            <Route path="/exam/add" element={<ExamCreate />} />
            <Route path="/exam/view" element={<ExamView />} />
            {/* <Route path="/analytics" element={<AnalyticsContent />} /> */}
            <Route path="/schedule-report" element={<ScheduleReport />} />
            <Route path="/fee-receivable-report" element={<FeeReceivableReport />} />
            <Route path="/fee-payment-report" element={<FeePaymentReport />} />
            <Route path="/student-report" element={<StudentReport />} />
            <Route path="/batch-report" element={<BatchReport />} />
            <Route path="/academic-report" element={<AcademicReport />} />
            <Route path="/attendance-report" element={<AttendanceRreport />} />
            <Route path="/transfer-report" element={<TransferReport />} />
            <Route path="/activity-logs" element={<ActivityLogs />} />
            <Route path="/academic-holiday/list" element={<AcademicHolidayList />} />
            <Route path="/academic-holiday/add" element={<CreateAcademicHoliday />} />
          </Routes>
        </Container>
      </div>
    // </Router>
  );
};


const AnalyticsContent = () => (
  <div>
    Analytics Content
  </div>
);

export default AdminPanel;
