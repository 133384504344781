import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './loginForm'
import SubmitForm from './submitForm'
import HomePage from './home'
import Attendance from './pages/attendance'
import Materials from './pages/materials'
import Acadamic from './pages/acadamic'
import Notification from './pages/notification'
import Payments from './pages/payments'
import Profile from './pages/profile';
import StudentRegistration from './registrationForm';

const StudentApp = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/submit" element={<SubmitForm />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/attendance" element={<Attendance />} />
        <Route path="/materials" element={<Materials />} />
        <Route path="/acadamics" element={<Acadamic />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/register" element={<StudentRegistration />} />
      </Routes>
    </div>
  );
};

export default StudentApp;