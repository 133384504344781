import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Autocomplete, Button, CircularProgress, FormControl, Grid, InputLabel, List, ListItem, MenuItem, Select, TextField, Typography } from '@mui/material';
import apiService from '../../../utlis/apiService';
import { GridToolbar } from '@mui/x-data-grid-pro';
import DownloadIcon from '@mui/icons-material/Download';
import { apiUrl } from '../../../config';

export default function ExportDefaultToolbar() {
    const [data,setData] = React.useState([]);
    const [filterData, setFilterData] = React.useState({
        search: '',
        course: '',
        category: '',
        courseStatus: '',
        studentStatus: '',
        branch: '',
        batch: '',
    });
    const [batches, setBatches] = React.useState([]);
    const [courses, setCourse] = React.useState([]);
    const [branches, setBranches] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [totalResults, setTotalResults] = React.useState(0);

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    });
    const [loading, setLoading] = React.useState(false);

    const pageOption = [{label: 10, value: 10},{label: 25, value: 25},{label: 50, value: 50},{label: 100, value: 100}];

    React.useEffect(()=> {
        getStudentsData();
    },[filterData, paginationModel]);

    React.useEffect(()=> {
        getData();
    },[]);

    React.useEffect(()=> {
        if(filterData.course)
            getCategory();
    },[filterData.course]);

    const getData = async () => {
        try {
            const batcheResponse = await apiService.get('/batch/list');
            const branchResponse = await apiService.get('/branch/list');
            const courseResponse = await apiService.get('/course/list');

            const runningBatch = batcheResponse?.data?.batchs?.filter(batch => batch.status === 'Running');
            setBatches(runningBatch);

            setBranches(branchResponse?.data?.branchs);
            setCourse(courseResponse?.data?.course);
        } catch (error) {
            console.error(error);
        }
    }

    const getCategory = async () => {
        try {
            const categoryResponse = await apiService.post(`/level/list/categoryByCourse`, {courseId: filterData.course});
            setCategories(categoryResponse?.data?.categories);
        } catch (error) {
            console.error(error);
        }
    }

    const getStudentsData = async () => {
        setLoading(true);
        try {
            const response = await apiService.get('/report/student', {
                params: {
                    ...filterData,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                }
            });

            setData(response?.data?.students);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeSubmit = (e, value, name) => {
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value?._id,
        }));
    };

    const handleChange = (name, value) => {
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const CustomCategory = (admissionProp) => {
        const admissions = admissionProp.value;

        return(
            <List>
                {admissions.map((admission, index) => (
                    admission?.category?.map((category, categoryIndex) => (
                        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index + categoryIndex + category?.category?._id} title={category?.category?.name}>{category?.category?.name?.substring(0, 10)}...</ListItem>
                    ))
                ))}
            </List>
        );
    }

    const CustomBranch = (admissionProp) => {
        const admissions = admissionProp.value;
        const uniqueBranchesMap = new Map();
        admissions.map(admission => (
            admission?.category.forEach(category => {
                category.batch.forEach(batch => {
                if (!uniqueBranchesMap.has(batch.branch_name)) {
                    uniqueBranchesMap.set(batch.branch_name, batch.branch.name);
                }
                });
            })
        ));
        const uniqueBranches = Array.from(uniqueBranchesMap.values());

        return(
            <List>
                {uniqueBranches?.map((branch, index) => {
                    return (
                        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index} title={branch}>{branch}</ListItem>
                    );
                })}
            </List>
        );
    }

    const CustomCourseStatus = (admissionProp) => {
        const admissions = admissionProp.value;
        const uniqueStatusType = new Map();

        admissions.map((admission) => (
            admission.category.forEach(category => {
                if (!uniqueStatusType.has(category.statusType)) {
                    uniqueStatusType.set('statusType', category.statusType);
                }
            })
        ));

        const uniqueBatches = Array.from(uniqueStatusType.values());

        return(
            <List>
                {uniqueBatches?.map((type, index) => {
                    return (
                        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index} title={type}>{type}</ListItem>
                    );
                })}
            </List>
        );
    }

    const CustomCourseFee = (admissionProp) => {
        const admissions = admissionProp.value;

        return(
            <List>
                {admissions.map((admission, index) => (
                    admission?.category?.map((category, categoryIndex) => (
                        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index + categoryIndex + category?.category?._id}>{category?.totalFee}</ListItem>
                    ))
                ))}
            </List>
        );
    }

    const CustomDiscount = (admissionProp) => {
        const admissions = admissionProp.value;
        
        return(
            <List>
                {admissions?.map((admission, index) => (
                    admission?.category?.map((category, categoryIndex) => {
                        let discount = 0;
                        category?.discount?.map((dis) => discount += parseFloat((dis.value)))
                        return <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index + categoryIndex + category?.category?._id}>{discount}</ListItem>
                    })
                ))}
            </List>
        );
    }

    const CustomPaid = (admissionProp) => {
        const admissions = admissionProp.value;

        return(
            <List>
                {admissions?.map((admission, index) => (
                    admission?.category?.map((category, categoryIndex) => {
                        let amount = 0;
                        if(category?.ots?.amount > 0 && category?.ots?.received){
                            amount += category?.ots?.amount;
                        }

                        category.feeSplit.map((fee) => {
                            amount += fee.received ? fee.value : 0;
                        });

                        return <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index + categoryIndex + category?._id}>{amount}</ListItem>
                    })
                ))}
            </List>
        );
    }

    const CustomBalance = (admissionProp) => {
        const admissions = admissionProp.value;

        return(
            <List>
                {admissions?.map((admission, index) => (
                    admission?.category?.map((category, categoryIndex) => {
                        let amount = 0;
                        if(category?.ots?.amount > 0 && !category?.ots?.received){
                            amount += category?.ots?.amount;
                        }

                        category.feeSplit.map((fee) => {
                            amount += !fee.received ? fee.value : 0;
                        });

                        return <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index + categoryIndex + category?._id}>{amount}</ListItem>
                    })
                ))}
            </List>
        );
    }

    const CustomDue = (admissionProp) => {
        const admissions = admissionProp.value;

        return(
            <List>
                {admissions?.map((admission, index) => (
                    admission?.category?.map((category, categoryIndex) => {
                        let amount = 0;
                        if(category?.ots?.amount > 0 && !category?.ots?.received && new Date(category?.ots?.dueDate) <= new Date()){
                            amount += category?.ots?.amount;
                        }

                        category.feeSplit.map((fee) => {
                            amount += !fee.received && new Date(fee?.dueDate) <= new Date() ? fee.value : 0;
                        });

                        return <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index + categoryIndex + category?._id}>{amount}</ListItem>
                    })
                ))}
            </List>
        );
    }

    const CustomDueBy = (admissionProp) => {
        const admissions = admissionProp.value;

        return(
            <List>
                {admissions?.map((admission, index) => (
                    admission?.category?.map((category, categoryIndex) => {
                        let otsDueDate = '';
                        if(category?.ots?.amount > 0 && !category?.ots?.received && new Date(category?.ots?.dueDate) <= new Date()){
                            otsDueDate = category?.ots?.dueDate;
                        }

                        const splitDues = category.feeSplit.filter(item => !item.received)
                        .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

                        if(splitDues.length && otsDueDate !== ''){
                            let dueDate =  Date(splitDues[0].dueDate) > new Date(otsDueDate) ? splitDues[0].dueDate : otsDueDate;
                            return <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index + categoryIndex + category?._id}>{new Date(dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</ListItem>
                        }
                        else if(splitDues.length) {
                            let dueDate = splitDues[0].dueDate;
                            return <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index + categoryIndex + category?._id}>{new Date(dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</ListItem>
                        }
                        else if(otsDueDate){
                            let dueDate = otsDueDate;
                            return <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index + categoryIndex + category?._id}>{new Date(dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</ListItem>
                        }
                        else {
                            return '-';
                        }
                    })
                ))}
            </List>
        );
    }

    const CustomRefund = (admissionProp) => {
        const admissions = admissionProp.value;

        return(
            <List>
                {admissions?.map((admission, index) => (
                    admission?.category?.map((category, categoryIndex) => {
                        if(!category.excessOrRefund.length) {
                            return '-'
                        }
                        category.excessOrRefund.map((refund) => {
                            if(refund.paid){
                                return <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index + categoryIndex + category?._id}>{refund.amount + '(' + new Date(refund.paidDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) + ')'}</ListItem>
                            }
                        });
                    })
                ))}
            </List>
        );
    }

    const CustomCompletedBatch = (admissionProp) => {
        const admissions = admissionProp.value;
        const uniqueBatchesMap = new Map();
        admissions.map(admission => (
            admission?.category.forEach(category => {
                category.batch.forEach(batch => {
                if (!uniqueBatchesMap.has(batch.name) && batch.status === 'Completed') {
                    uniqueBatchesMap.set(batch.name, batch.name);
                }
                });
            })
        ));
        const uniqueBatches = Array.from(uniqueBatchesMap.values());

        return uniqueBatches.length;
    }

    const CustomRunningBatch = (admissionProp) => {
        const admissions = admissionProp.value;
        const uniqueBatchesMap = new Map();
        admissions.map(admission => (
            admission?.category.forEach(category => {
                category.batch.forEach(batch => {
                if (!uniqueBatchesMap.has(batch.name) && batch.status === 'Running') {
                    uniqueBatchesMap.set(batch.name, batch.name);
                }
                });
            })
        ));
        const uniqueBatches = Array.from(uniqueBatchesMap.values());

        return uniqueBatches.length;
    }

    const CustomCurrentBatch = (admissionProp) => {
        const admissions = admissionProp.value;
        const uniqueBatchesMap = new Map();
        admissions.map(admission => (
            admission?.category.forEach(category => {
                category.batch.forEach(batch => {
                if (!uniqueBatchesMap.has(batch.name) && batch.status === 'Running') {
                    uniqueBatchesMap.set(batch.name, batch.name);
                }
                });
            })
        ));
        const uniqueBatches = Array.from(uniqueBatchesMap.values());

        return(
            <List>
                {uniqueBatches?.map((batch, index) => {
                    return (
                        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index} title={batch}>{batch.substring(0, 15)}...</ListItem>
                    );
                })}
            </List>
        );
    }

    const exportReport = async () => {
        try {
            const response = await apiService.get('/export/report/student', {
                params: {
                    ...filterData,
                },
            });

            if(response?.data?.status === 'success') {
                
                window.open(`${apiUrl}${response?.data?.path}`, '_blank');
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <div style={{ height: '75vh', width: '100%' }}>
            <Typography variant="h4" gutterBottom>
                STUDENTS REPORT
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                    <TextField
                        label="Name / Phone Number"
                        margin="normal"
                        name="search"
                        value={filterData.search}
                        onChange={(e) => handleChange('search', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-course"
                        name="course"
                        options={courses}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={courses?.find((option) => option._id === filterData.course) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'course')}
                        renderInput={(params) => <TextField {...params} label="Course" />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-category"
                        name="category"
                        options={categories}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={categories.find((option) => option._id === filterData.category) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'category')}
                        renderInput={(params) => <TextField {...params} label="Category" />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth sx={{ marginTop: '15px' }}>
                        <InputLabel id="CourseStatus">Course Status</InputLabel>
                        <Select
                            label="Course Status"
                            value={filterData.courseStatus}
                            onChange={(e) => handleChange('courseStatus', e.target.value)}
                            fullWidth
                            name="courseStatus"
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Prime">Prime</MenuItem>
                            <MenuItem value="Prime Plus">Prime Plus</MenuItem>
                            <MenuItem value="Level">Level</MenuItem>
                            <MenuItem value="Paper">Paper</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2}}>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-branch"
                        name="branch"
                        options={branches}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={branches.find((option) => option._id === filterData.branch) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'branch')}
                        renderInput={(params) => <TextField {...params} label="Branch" />}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth sx={{ marginTop: '15px' }}>
                        <InputLabel id="StudentStatus">Student Status</InputLabel>
                        <Select
                            label="studentStatus"
                            value={filterData.studentStatus}
                            onChange={(e) => handleChange('studentStatus', e.target.value)}
                            fullWidth
                            name="studentStatus"
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">Inactive</MenuItem>
                            <MenuItem value="Drop">Drop</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-batch"
                        name="batch"
                        options={batches}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={batches.find((option) => option._id === filterData.batch) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'batch')}
                        renderInput={(params) => <TextField {...params} label="Current Batch" />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 3 }}>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={8}>
                    <Button variant="outlined" sx={{ float: 'right' }} title="Export" onClick={exportReport}><DownloadIcon /></Button>
                </Grid>
            </Grid>
            {loading ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress style={{ margin: 'auto' }} />
                </div>

            ) : (
                <DataGridPro
                    getRowId={(row) => row._id}
                    rows={data}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    csvOptions={{
                        delimiter: ';',
                        fileName: 'my-data-grid-export',
                        utf8WithBom: true,
                    }}
                    disableDensitySelector
                    columns={[
                        { field: '_id', headerName: 'ID', width: 100 },
                        { 
                            field: 'createdAt',
                            headerName: 'Date', 
                            width: 150, 
                            valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })
                        },
                        { field: 'name', headerName: 'Name', width: 150, valueGetter: (params) => params.row.firstName + ' ' + params.row.lastName },
                        { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
                        { 
                            field: 'category', 
                            headerName: 'Category', 
                            width: 150, 
                            renderCell: (params) => <CustomCategory value={params?.row?.admission} />
                        },
                        { 
                            field: 'branches', 
                            headerName: 'Branchs', 
                            width: 150, 
                            renderCell: (params) => <CustomBranch value={params?.row?.admission} /> 
                        },
                        {   
                            field: 'statusType',
                            headerName: 'Course Status',
                            width: 150,
                            renderCell: (params) => <CustomCourseStatus value={params?.row?.admission} /> 
                        },
                        {   field: 'totalFee',
                            headerName: 'Course Fee',
                            width: 150,
                            renderCell: (params) => <CustomCourseFee value={params?.row?.admission} />
                        },
                        { 
                            field: 'discount', 
                            headerName: 'Discount', 
                            width: 150, 
                            renderCell: (params) => <CustomDiscount value={params?.row?.admission} />
                        },
                        { 
                            field: 'paid',
                            headerName: 'Paid', 
                            width: 150, 
                            renderCell: (params) => <CustomPaid value={params?.row?.admission} />
                        },
                        { 
                            field: 'balance', 
                            headerName: 'Balance',
                            width: 150, 
                            renderCell: (params) => <CustomBalance value={params?.row?.admission} />
                        },
                        { 
                            field: 'due', 
                            headerName: 'Due', 
                            width: 150, 
                            renderCell: (params) => <CustomDue value={params?.row?.admission} />
                        },
                        { 
                            field: 'dueBy', 
                            headerName: 'Due By', 
                            width: 150, 
                            renderCell: (params) => <CustomDueBy value={params?.row?.admission} />
                        },
                        { field: 'status', headerName: 'Student Status', width: 150 },
                        { field: 'cancelledDate', headerName: 'Cancelled Date', width: 150 },
                        {
                            field: 'refund',
                            headerName: 'Refund',
                            width: 150,
                            renderCell: (params) => <CustomRefund value={params?.row?.admission} />
                        },
                        {
                            field: 'completedBatchCount',
                            headerName: 'Attended Batch Count',
                            width: 150,
                            renderCell: (params) => <CustomCompletedBatch value={params?.row?.admission} />
                        },
                        {
                            field: 'runningBatchCount',
                            headerName: 'Running Batch Count',
                            width: 150,
                            renderCell: (params) => <CustomRunningBatch value={params?.row?.admission} />
                        },
                        {
                            field: 'currentBatch', 
                            headerName: 'Current Batch',
                            width: 150,
                            renderCell: (params) => <CustomCurrentBatch value={params?.row?.admission} />
                        },
                    ]}
                    pagination
                    paginationModel={paginationModel}
                    rowCount={totalResults}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    rowsPerPageOptions={pageOption}
                />
            )}
        </div>
    );
}
