import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useLocation } from 'react-router-dom';
import apiService from '../../../utlis/apiService';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import Students from './getStudents'
import Materials from './uploadMaterial'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormLabel, InputLabel, MenuItem, Select } from '@mui/material';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'; // Import the advancedFormat plugin
import 'dayjs/locale/en';
import Schdule from './manageSchedule';
import ManageExam from './manageExam';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ActivityLogs from './log'
import { useUser } from '../../../context/userContext';

dayjs.extend(AdvancedFormat);

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ViewbatchForm = () => {
  let query = useQuery();
  let id = query.get("id")
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    branch:'',
    course:'',
    mentor:'',
    faculty:'',
    coordinator: '',
    year:'',
    paper:'',
    limit: '',
    date: null,
    status: '',
    mode: '',
    session: ''
  });
  const [papers,setPapers]=useState([])
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [exams, setExams] = useState([]);

  const { permissions } = useUser();


  const fetchbatchData = async () => {
    try {
      const response = await apiService.get(`/batch/get`, {
        params: {
          id: id,
        },
      });
  
      setFormData({ ...response?.data, student: [], exam: []});
      setSelectedStudents(response?.data?.student || []);
      setMaterials(response?.data?.materials);
      setSchedule(response?.data?.schedule);
      setExams(response?.data?.exam);
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    if (id) {
      
      fetchbatchData();
    }
  }, [id]);

  

  const [branchs,setBranchs]=useState([]);

  const [courses,setCourses]=useState([]);

  const [mentors,setMentors]=useState([]);

  const [faculties,setFaculties]=useState([]);

  const [coordinator,setCoordinator] = useState([])

  const [years, setYears] = useState([
    '2020-2021',
    '2021-2022',
    '2022-2023',
    '2023-2024',
    '2024-2025'
  ]);

  const [sessions, setSessions] = useState([
    'Jun',
    'Sept',
    'Dec',
    'Mar'
  ]);

  useEffect(()=>{
    getAllData()
  },[])

  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem('batchFormData'));
    const storedTimestamp = localStorage.getItem('batchFormDataTimestamp');

    if (storedFormData && isRecent(storedTimestamp)) {
      setFormData(storedFormData);
    }
  }, []);


  useEffect(()=>{
    if (formData.course){
      getPapersByCourse(formData.course)
    }
  },[formData.course]);

  const isRecent = (timestamp) => {
    const currentTimestamp = new Date().getTime();
    return currentTimestamp - timestamp < 50000; 
  };

  const getAllData = async () => {
    try {
      const [branchResponse, courseResponse, mentorResponse, facultyResponse, coordinatorResponse] = await Promise.all([
        apiService.get('/branch/list'),
        apiService.get('/course/list'),
        apiService.get('/mentor/list'),
        apiService.get('/faculty/list'),
        // apiService.get('/class/list'),
        apiService.get('/coordinator/list'),
      ]);
  
      const branch = branchResponse?.data?.branchs;
      const course = courseResponse?.data?.course;
      const mentor = mentorResponse?.data?.mentor;
      const faculty = facultyResponse?.data?.faculty;
      const coordinator = coordinatorResponse?.data?.coordinator;
  
      setBranchs(branch);
      setCourses(course);
      setMentors(mentor);
      setFaculties(faculty);
      setCoordinator(coordinator);
    } catch (error) {
      console.error('Error during requests:', error);
    }
  };

  const onChangeStudent=(newValue)=>{
    if(newValue.length > formData.limit)
      alert('Maximum batch capacity reached!');
    else
      setSelectedStudents(newValue);
  }

  const getPapersByCourse=async(cid)=>{
    try {
      const response = await apiService.get(`/paper/paperbycourse?id=${cid}`);
      setPapers(response.data)
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <h1 style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
        <BackButton/>
        {`Batches`}
      </h1>
      <form>
        <Typography variant="h4" gutterBottom>
          View Batch
        </Typography>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="year"
          options={years}
          style={{ marginTop: 15 }}
          getOptionLabel={(option) => option}  
          value={formData.year || null}
          renderInput={(params) => <TextField {...params} label="Academic Year" />}
          disabled={true}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="session"
          options={sessions}
          style={{ marginTop: 15 }}
          getOptionLabel={(option) => option}  
          value={formData.session || null}
          renderInput={(params) => <TextField {...params} label="Session" />}
          disabled={true}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="branch"
          options={branchs}
          style={{marginTop:15}}
          getOptionLabel={(option) => option.name}
          value={branchs.find((option) => option._id === formData.branch) || null}
          renderInput={(params) => <TextField {...params} label="Branch" />}
          disabled={true}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="courses"
          options={courses}
          style={{marginTop:15}}
          getOptionLabel={(option) => option.name}
          value={courses.find((option) => option._id === formData.course) || null}
          disabled={true}
          renderInput={(params) => <TextField {...params} label="Add Course" />}
        />
        
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="paper"
          required
          options={papers}
          style={{ marginTop: 15 }}
          getOptionLabel={(option) => option.name} 
          value={papers.find((option) => option._id === formData.paper) || null}
          disabled={true}
          renderInput={(params) => <TextField {...params} label="Subject" />}
        />

      <TextField
        label="Capacity"
        name="limit"
        value={formData.limit}
        disabled={true}
        fullWidth
        sx={{marginTop:'40px'}}
        margin="normal"
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 15 }}>
          <FormLabel htmlFor='activity-time' className='text-sm' required>
            Starting Date
          </FormLabel>
          <MobileDatePicker
            value={dayjs(formData.date)}
            disabled={true}
            sx={{ marginTop: 1 }}
          />
        </FormControl>
      </LocalizationProvider>

      <FormControl fullWidth sx={{ marginTop: '40px' }}>
        <InputLabel id="Select Status">Select Status</InputLabel>
        <Select
          label="Select Status"
          value={formData.status}
          disabled={true}
          fullWidth
          name="status"
        >
          <MenuItem value="Upcoming">Upcoming</MenuItem>
          <MenuItem value="Open For Enrollment">Open For Enrollment</MenuItem>
          <MenuItem value="Running">Running</MenuItem>
          <MenuItem value="Completed">Completed</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginTop: '40px' }}>
        <InputLabel id="Select Mode">Select Mode</InputLabel>
        <Select
          label="Select Mode"
          value={formData.mode}
          disabled={true}
          fullWidth
          name="mode"
        >
          <MenuItem value="Offline">Offline</MenuItem>
          <MenuItem value="Online">Online</MenuItem>
          <MenuItem value="Hybrid">Hybrid</MenuItem>
        </Select>
      </FormControl>
      
      <Autocomplete
        disablePortal
        id="combo-box-faculty"
        name="faculty"
        options={faculties}
        style={{ marginTop: '40px' }}
        getOptionLabel={(option) => option.name}
        value={faculties.find((option) => option._id === formData.faculty) || null}
        disabled={true}
        renderInput={(params) => <TextField {...params} label="Faculty" />}
      />

      <Autocomplete
        disablePortal
        id="combo-box-mentor"
        name="mentor"
        options={mentors}
        style={{ marginTop: '40px' }}
        getOptionLabel={(option) => option.name}
        value={mentors.find((option) => option._id === formData.mentor) || null}
        disabled={true}
        renderInput={(params) => <TextField {...params} label="Mentor" />}
      />

      <Autocomplete
        disablePortal
        id="combo-box-coordinator"
        name="coordinator"
        options={coordinator}
        style={{ marginTop: '40px' }}
        getOptionLabel={(option) => option.name}
        value={coordinator.find((option) => option._id === formData.coordinator) || null}
        disabled={true}
        renderInput={(params) => <TextField {...params} label="Coordinator" />}
      />

      <Students selectedStudents={selectedStudents} setSelectedStudents={onChangeStudent} paper={formData.paper} mode={formData.mode} batchId={id} viewMode={true} />

      <Materials materials={materials} setMaterials={setMaterials} viewMode={true} />

      <Schdule schedule={schedule} getBatch={fetchbatchData} viewMode={true} />

      {(permissions.includes('readExam') && id) && (
        <ManageExam exams={exams} students={selectedStudents} batchReload={fetchbatchData} batchId={id} viewMode={true} />
      )}
        
      </form>

      <div style={{ marginTop: '2rem' }}>
        <Accordion defaultExpanded ={!id}>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="log-content"
            id="log-header"
          >
            <Typography>Activity Logs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ActivityLogs id={id} />
          </AccordionDetails>
        </Accordion>
      </div>

    </div>
  );
};

export default ViewbatchForm;
