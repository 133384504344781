const generateYearRange = () => {
    const today = new Date();

    const currentYear = today.getFullYear();
    const ranges = [];
    const startYear = 2020;
    
    // Generate year ranges up to the current year
    for (let year = startYear; year <= currentYear; year++) {
        const yearEnd = year + 1;
        ranges.push(`${year}-${yearEnd}`);
    }
    
    return ranges;
}

const getSessions = () => {
    return [
        'Jun',
        'Sept',
        'Dec',
        'Mar'
    ];
}

export { generateYearRange, getSessions }