// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './admin/Login';
import Dashboard from './admin/App';
import Student from './student/App';
import Faculty from './faculty/App'
import FacultyLogin from './faculty/loginForm'
import ProtectedRoute from '../src/utlis/protectedRoute';
import ProtectedFroute from '../src/utlis/protectedFroute';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/dashboard/*"
          element={<ProtectedRoute element={<Dashboard />} />}
        />
        <Route path="/admin" element={<Login />} />
        <Route path="/student/*" element={<Student />} />

        <Route
          path="/faculty/*"
          element={<ProtectedFroute element={<Faculty />} />}
        />
        <Route path="/faculty-login" element={<FacultyLogin />} />
        <Route path="/" element={<Navigate to="/student" />} />
      </Routes>
    </Router>
  );
};

export default App;
