import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import apiFaculty from '../utlis/apiFaculty';
import config from '../config';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import dayjsLocalized from 'dayjs/plugin/localeData';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/en';
import 'react-big-calendar/lib/css/react-big-calendar.css';


dayjs.extend(AdvancedFormat);
dayjs.extend(dayjsLocalized);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);

const localizer = dayjsLocalizer(dayjs);

const MyDashboard = () => {
  const navigate = useNavigate();
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [filterFromDate, setFilterFromDate] = useState('');
  const [filterToDate, setFilterToDate] = useState('');
  const [branchs, setBranchs] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [filter, setFilter] = useState({
    date: '',
    branch: '',
  });

  useEffect(() => {
    getData();
  }, [filter]);

  const getData = async () => {
    try {
      const response = await apiFaculty.get(`${config.apiUrl}/admin/faculty/home`, {
        params: {
            ...filter,
        },
      });
      const scheduleList = [];
      
      response?.data?.schedules.map((schedule) => {
        const event = {
          title: `${schedule?.batch?.course?.name}, ${schedule?.batch?.branch?.name} `,
          start: new Date(formatDateTime(schedule.date, schedule.start)),
          end: new Date(formatDateTime(schedule.date, schedule.end)),
          allDay: false,
          // resource?: any,
        }

        scheduleList.push(event);
      });

      // setSchedules(response?.data?.schedules || []);
      setSchedules(scheduleList || []);
      setBranchs(response?.data?.branchs || []); // Assuming locations are returned from the API
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const formatDateTime = (dateString, timeString) => {
    const dateObj = new Date(dateString);
    const timeObj = new Date(timeString);
    
    // Extract date part from the dateString
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth(); // Note: Months are 0-based
    const day = dateObj.getDate();

    // Extract hours and minutes from the timeString
    const hours = timeObj.getHours();
    const minutes = timeObj.getMinutes();

    // Construct new Date object with the combined information
    const combinedDate = new Date(year, month, day, hours, minutes);
    
    return combinedDate.toISOString();
  };

  const logoutUser = () => {
    localStorage.removeItem('ftoken')
    navigate('/faculty-login')
  };

  const onNavigate = useCallback((newDate) => {
    const dateObj = new Date(newDate);
    
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth();
    const day = 1;
    
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();

    // Construct new Date object with the combined information
    const combinedDate = new Date(year, month, day, hours, minutes);
    
    setFilter((prevData) => ({
      ...prevData,
      date: combinedDate.toISOString()
    }));
    
   }, [])

  return (
    <Container maxWidth="md" sx={{ marginTop: 5 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 3 }}
      >
        <Typography variant="h4">DASHBOARD</Typography>
        <IconButton color="primary" onClick={logoutUser}>
          <Typography variant="h6">Logout</Typography>
        </IconButton>
      </Box>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: "center"}}>
        <Box sx={{ mb: 3 }} >
          <FormControl sx={{ marginLeft: 0, width: 200 }}>
            <InputLabel id="location-select-label">Branch</InputLabel>
            <Select
              labelId="location-select-label"
              label="Branch"
              id="location-select"
              value={filter.branch}
              onChange={(e) => setFilter((prevData) => ({...prevData, branch: e.target.value}))}
            >
              <MenuItem value="">All Branchs</MenuItem>
              {branchs.map(branch => (
                <MenuItem key={branch._id} value={branch._id}>{branch.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Button type='button' variant="contained" onClick={() => navigate('/faculty/leaves')}>
          Leaves
        </Button>
      </div>
      <Grid container spacing={3} sx={{ marginTop: '20px', marginLeft: 0 }}>
        <Calendar 
          localizer={localizer}
          events={schedules}
          startAccessor="start"
          endAccessor="end"
          onNavigate={onNavigate}
          style={{ height: 500, width: '100%' }}
        />
      </Grid>
    </Container>
  );
};

export default MyDashboard;
