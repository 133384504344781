import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { MuiTelInput } from 'mui-tel-input';
import { CircularProgress, FormHelperText, Tab } from '@mui/material';
import Intercom from "@intercom/messenger-js-sdk";
import { TabContext, TabList, TabPanel } from '@mui/lab';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://elancelearning.com/">
        Elance Learning
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// const SignUp = () => {
//   return (
//     <Typography variant="body2" color="text.secondary" align="left">
//       <RouterLink color="text.primary" to="/student/register">
//         Click Here
//       </RouterLink>
//       {' '} to Sign Up
//     </Typography>
//   );
// }


const defaultTheme = createTheme();

export default function SignInSide() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [phone, setPhone] = useState('+91');
  const [Otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [timeLeftSeconds, setTimeLeftSeconds] = useState(null);
  const [isCounting, setIsCounting] = useState(false);
  const [otpMethodTabValue, setOtpMethodTabValue] = useState('Login-With-OTP');
  const [loginType, setLoginType] = useState('phone');
  const [isLoading, setIsLoading] = useState(false);

  const handleSendSubmit = async (event = false) => {
    event.preventDefault();
    
    try {
      const formData = new FormData(event.currentTarget);
      const phone = formData.get('phone');
      handleSendOtp(phone);

    } catch (error) {
      console.error('Error during login:', error.message);
    }
    
  };

  const handleSendOtp = async (phone) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${config.apiUrl}/student/login`, {
        phone: phone,
        type: loginType
      });

      if(response.data.status === 'success'){
        setPage(2);
        setIsLoading(false);
      }
      else{
        alert('Somthing went wrong. Please try again!');
        setIsLoading(false);
        return;
      }

      startCountdown();
    } catch (error) {
      
      if(error?.response && error.response.status === 401){
        setError(error?.response?.data);
      }
      
      if(error?.response && error.response.status === 400){
        setError(error?.response?.data?.error);
      }
      console.error('Error during login:', error.message);
    }
  }

  const handleVerifyOtpSubmit = async (event) => {
    event.preventDefault();

    try{
      const formData = new FormData(event.currentTarget);
      const otp = formData.get('otp');
      const response = await axios.post(`${config.apiUrl}/student/verify/otp`, {
          phone: phone,
          otp: otp,
          type: loginType,
      });

      console.log(response?.data?.token);
      if(response?.data?.status === 'success'){
        console.log(response?.data, 'response');
        localStorage.setItem('stoken',response?.data?.token);
        localStorage.setItem('user_id', response?.data?.user?._id);
        navigate('/student/home');
        window.location.reload();
      } else {
        console.error('login failed:');
      }

    } catch (error) {
      console.error('Error during otp verification:', error.message);
    }
  }

  const handleChangePhone = (value, type) => {
    setPhone(value);
    setLoginType(type);
  }

  const handleChangeOtp = (e) => {
    setOtp(e.target.value);
  }

  function startCountdown() {
    setTimeLeftSeconds(60);
    setIsCounting(true);
  }

  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeftSeconds / 60);
    const seconds = timeLeftSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    if (isCounting && timeLeftSeconds !== null) {
      const intervalId = setInterval(() => {
        setTimeLeftSeconds(prevTimeLeft => {
          if (prevTimeLeft <= 0) {
            setIsCounting(false); // Stop the countdown when time is up
            clearInterval(intervalId);
            return 0;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isCounting, timeLeftSeconds]);

  const getDetails = async () => {
    try {
      Intercom({
          api_base: "https://api-iam.intercom.io",
          app_id: "asspy0k2",
          hide_default_launcher: false
      });

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

const handleOtptabChange = (e, value) => {
  setOtpMethodTabValue(value);
  setPhone('+91');
}

useEffect(() => {
    getDetails();
}, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh',display:'flex',justifyContent:'center',alignItems:'center' }}>
        <CssBaseline />
        <Grid item xs={11} sm={6} md={3} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius:8
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {page === 1 ?
              <>
                <TabContext value={otpMethodTabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleOtptabChange} aria-label="Login tab">
                      <Tab label="Login With OTP" value="Login-With-OTP" />
                      <Tab label="Login With WhatsApp" value="Login-With-WhatsApp" />
                    </TabList>
                  </Box>
                  <TabPanel value="Login-With-OTP">
                    <Box component="form" noValidate onSubmit={handleSendSubmit} sx={{ mt: 1 }}>
                      <MuiTelInput 
                        margin="normal"
                        required
                        fullWidth
                        id="phone"
                        label="Phone Number"
                        name="phone"
                        autoComplete="phone"
                        autoFocus
                        value={phone}
                        onChange={(data) => handleChangePhone(data, 'Phone')}
                      />
                      {error && 
                        <FormHelperText error='true'>{error}</FormHelperText>
                      }
                      {isLoading ? 
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                            <CircularProgress style={{ margin: 'auto', color: '#fff' }} />
                        </Button>
                      :
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Send OTP
                        </Button>
                      }
                    </Box>
                  </TabPanel>
                  <TabPanel value="Login-With-WhatsApp">
                    <Box component="form" noValidate onSubmit={handleSendSubmit} sx={{ mt: 1 }}>
                      <MuiTelInput 
                        margin="normal"
                        required
                        fullWidth
                        id="whatsappNumber"
                        label="Whatsapp Number"
                        name="phone"
                        value={phone}
                        onChange={(data) => handleChangePhone(data, 'Whatsapp')}
                      />
                      {error && 
                        <FormHelperText error='true'>{error}</FormHelperText>
                      }
                      {isLoading ? 
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                            <CircularProgress style={{ margin: 'auto', color: '#fff' }} />
                        </Button>
                      :
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Send OTP
                        </Button>
                      }
                    </Box>
                  </TabPanel>
                </TabContext>
                <Copyright sx={{ mt: 5 }} />
              </>
            : 
              <Box component="form" noValidate onSubmit={handleVerifyOtpSubmit} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="otp"
                    label="OTP"
                    name="otp"
                    autoFocus
                    value={Otp}
                    onChange={(e) => handleChangeOtp(e)}
                  />
                  {timeLeftSeconds === 0 ?
                    <Grid container>
                      <Grid item xs sx={{fontSize: '13px', color: '#1976d2', cursor: 'pointer'}}>
                        <span onClick={() => handleSendOtp(phone)}>Resend OTP</span>
                      </Grid>
                    </Grid>
                  :
                  <Grid container center>
                    <Grid item xs sx={{fontSize: '13px', color: '#1976d2'}}>
                      {formatTimeLeft()} sec left
                    </Grid>
                  </Grid>
                  }
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Verify OTP
                  </Button>
                  <Copyright sx={{ mt: 5 }} />
                </Box>
              }
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}