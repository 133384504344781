import * as React from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';
import apiService from '../../../utlis/apiService';



export default function ExportDefaultToolbar() {

    const [data,setData] = React.useState([])

    React.useEffect(()=>{
        getData()
    },[])

    const getData = async () => {
        try {
          const response = await apiService.get('/report/batch');
          console.log(response?.data?.batchs)
          setData(response?.data?.batchs)
        } catch (error) {
          console.error(error);
        }
      };

  return (
    <div style={{ height: 300, width: '100%' }}>
        <Typography variant="h4" gutterBottom>
            BATCHS REPORT
        </Typography>
       <DataGridPro
        getRowId={(row) => row._id}
        rows={data}
        disableDensitySelector
        columns={[
            { field: '_id', headerName: 'ID', width: 250 },
            { field: 'name', headerName: 'Name', width: 150 },
            { 
                field: 'branch', 
                headerName: 'Branch', 
                width: 150, 
                valueGetter: (params) => params.row?.branch?.name
            },
            { 
                field: 'course', 
                headerName: 'Course', 
                width: 150, 
                valueGetter: (params) => params.row?.course?.name
            },
            { 
                field: 'paper', 
                headerName: 'Paper', 
                width: 150, 
                valueGetter: (params) => params?.row?.paper?.name 
            },
        ]}
        components={{ Toolbar: GridToolbar }}
        />

    </div>
  );
}
