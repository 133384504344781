import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    ArrowBackIos as BackButton,
} from '@mui/icons-material';
import { Autocomplete, Button, FormControl, FormLabel, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import apiService from "../../../utlis/apiService";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; 

dayjs.extend(AdvancedFormat);

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateExamForm = () => {
    let query = useQuery();
    let id = query.get("id")
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        subject: '',
        examType: '',
        totalMark: '',
        passMark: '',
        batch: '',
        session: '',
        status: '',
    });
    const [subjects, setSubjects] = useState([]);
    const [batches, setBatches] = useState([]);
    const [searchSubject, setSearchSubject] = useState('');
    const [searchBatch, setSearchBatch] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        name: '',
        subject: '',
        date: null,
        examType: '',
        totalMark: '',
        passMark: '',
        batch: '',
        session: '',
    });

    const fetchExamData = async () => {
        try {
            if (id) {
                const response = await apiService.get('/exam/get', {
                    params: {
                        id: id,
                    },
                });
                setFormData(response?.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchSubject = async (query) => {
        setLoading(true);
        try {
            const response = await apiService.get('/paper/list', {
                params: {
                    searchTerm: query,
                },
            });
            setSubjects(response?.data?.papers ?? []);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBatch = async (query) => {
        try {
            const response = await apiService.get('/batch/list', {
                params: {
                    searchTerm: query,
                },
            });
            setBatches(response?.data?.batchs ?? []);

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchExamData();
    }, [id]);

    useEffect(() => {
        fetchSubject(searchSubject);
    }, [searchSubject]);

    useEffect(() => {
        fetchBatch(searchBatch);
    }, [searchBatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            date: date,
        }));
    }

    const handleChangeSubmit = (e, value, name) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value?._id,
        }));
    };

    const handleSearchSubjectOptionChange = (event, newQuery) => {
        setSearchSubject(newQuery);
        setSubjects([]);
    }

    const handleSearchBatchOptionChange = (event, newQuery) => {
        setSearchBatch(newQuery);
        setBatches([]);
    }

    const validateForm = () => {
        let isValid = true;
        const newErrors = {
            name: '',
            subject: '',
            examType: '',
            totalMark: '',
            passMark: '',
            batch: '',
            session: '',
        };

        if(!formData.name){
            isValid = false;
            newErrors.name = 'Name is required';
        }
        if(!formData.subject){
            isValid = false;
            newErrors.subject = 'Subject is required';
        }
        if(!formData.examType){
            isValid = false;
            newErrors.examType = 'Exam Type is required';
        }
        if(!formData.totalMark){
            isValid = false;
            newErrors.totalMark = 'total Mark Type is required';
        }
        if(!formData.passMark){
            isValid = false;
            newErrors.passMark = 'Pass Mark Type is required';
        }

        setErrors(newErrors);
        return isValid;
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
    
        if (validateForm()) {
            const apiUrl = id ? `/exam/update?id=${id}` : '/exam/create';
    
            try {
                const response = await apiService.post(apiUrl, formData);
                navigate(-1);
                console.log(JSON.stringify(response.data));
            } catch (error) {
                alert(error?.response?.data?.error);
                console.error(error);
            }
        }
    };

    return (
        <div>
            <h1 style={{ cursor:'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
                <BackButton />
                {`Exams`}
            </h1>
            <form onSubmit={handleSubmit}>
                <Typography variant="h4" gutterBottom>
                    {id ? 'Edit' : 'Create'} Exam
                </Typography>
                <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                />
                <FormControl fullWidth sx={{ marginTop: '10px' }}>
                    <InputLabel id="Exam Type">Exam Type *</InputLabel>
                    <Select
                        label="Exam Type"
                        value={formData.examType}
                        onChange={handleChange}
                        fullWidth
                        name="examType"
                        required
                        error={Boolean(errors.examType)}
                        helperText={errors.examType}
                    >
                        <MenuItem value="Main">Main</MenuItem>
                        <MenuItem value="Mock">Mock</MenuItem>
                    </Select>
                </FormControl>
                {formData.examType === 'Main' &&
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                        <InputLabel id="Session">Session *</InputLabel>
                        <Select
                            label="Session"
                            value={formData.session}
                            onChange={handleChange}
                            fullWidth
                            name="session"
                            required
                        >
                            <MenuItem value="Jun">Jun</MenuItem>
                            <MenuItem value="Sept">Sept</MenuItem>
                            <MenuItem value="Dec">Dec</MenuItem>
                            <MenuItem value="Mar">Mar</MenuItem>
                        </Select>
                    </FormControl>
                }
                {formData.examType === 'Mock' &&
                    <Autocomplete
                        id="combo-box-batch"
                        required
                        loading={loading}
                        options={batches}
                        style={{ marginTop: '10px' }}
                        getOptionLabel={(option) => option.name}
                        value={batches.find((option) => option._id === formData.batch) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'batch')}
                        // onInputChange={handleSearchBatchOptionChange}
                        renderInput={(params) => <TextField {...params} label="Batch *" />}
                    />
                }
                <Autocomplete
                    disablePortal
                    id="combo-box-subject"
                    required
                    options={subjects}
                    style={{ marginTop: '10px' }}
                    getOptionLabel={(option) => option.name}
                    value={subjects.find((option) => option._id === formData.subject) || null}
                    onChange={(e, value) => handleChangeSubmit(e, value, 'subject')}
                    // onInputChange={handleSearchSubjectOptionChange}
                    renderInput={(params) => <TextField {...params} label="Subject *" />}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                    <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
                    <FormLabel htmlFor='activity-time' className='text-sm' required>
                        Select Date
                    </FormLabel>
                    <MobileDatePicker
                        value={formData.date ? dayjs(formData.date) : null}
                        onChange={(date) => handleDateChange(date)}
                        sx={{ marginTop: 1 }}
                        format="DD/MM/YYYY"
                    />
                    </FormControl>
                </LocalizationProvider>
                <TextField
                    label="Total Mark"
                    name="totalMark"
                    value={formData.totalMark}
                    onChange={handleChange}
                    fullWidth
                    required
                    error={Boolean(errors.totalMark)}
                    helperText={errors.totalMark}
                    margin="normal"
                />
                <TextField
                    label="Pass Mark"
                    name="passMark"
                    value={formData.passMark}
                    onChange={handleChange}
                    fullWidth
                    required
                    error={Boolean(errors.passMark)}
                    helperText={errors.passMark}
                    margin="normal"
                />
                <FormControl fullWidth style={{ marginTop: '20px'}}>
                    <InputLabel id="Status">Status</InputLabel>
                    <Select
                        label="Status"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Inactive">Inactive</MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                    </Select>
                </FormControl>

                <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                    {id ? 'Update Exam' : 'Create Exam' }
                </Button>
            </form>
        </div>
    );
}

export default CreateExamForm;